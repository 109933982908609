import { Component, OnInit } from '@angular/core';
import { BaseComponent, SnackService } from '@evo/ui/common';
import { DefaultApi, Org } from '@evo/generated/admin';
import { Router } from '@angular/router';

// this will come from the server generated type
class Post {
  id: string;
}

@Component({
  templateUrl: './system-posts-page.component.html',
})
export class SystemPostsPageComponent extends BaseComponent implements OnInit {
  post: Post;
  org: Org;
  posts: Post[];

  constructor(
    private snackBar: SnackService,
    private api: DefaultApi,
    private router: Router
  ) {
    super();
  }

  async ngOnInit() {
    await this.loadPosts();
  }

  async loadPosts() {
    this.posts = [];
    this.posts = (await this.api.blogPostsControllerIndex()).data;
  }

  editPost(post: Post) {
    if (post?.id) {
      this.router.navigateByUrl(`admin/post/${post.id}`);
    }
  }

  async deletePost(post: Post) {
    this.busy$$.next(true);
    try {
      this.busy$$.next(false);
      await this.loadPosts();
      this.snackBar.deleted();
    } catch (error) {
      this.snackBar.oops();
    }
  }

  create() {
    // this.router.navigateByUrl(`admin/create/post`);
    this.snackBar.open('not implemented yet');
  }
}
