<mat-card class="mat-elevation-z8 mw-880">
  <evo-busy [busy]="!org || !user">
    <h2>
      <div class="flex-1 row space-between">
        <div>
          <mat-icon>label</mat-icon> Promo Codes
        </div>
        <button mat-raised-button color="primary" class="mb-1" (click)="create()"><mat-icon>new_label</mat-icon></button>
      </div>
    </h2>
    <mat-card class="mat-elevation-z0 mb-4" *ngFor="let promo of stripePromos">
      <h2>{{promo.id}}</h2>
      <dyna-promo-form [data]="promo" (valueChange)="valueChange($event)" (statusChange)="statusChange($event)" [readOnlyCode]="true"></dyna-promo-form>
    </mat-card>
  </evo-busy>
</mat-card>