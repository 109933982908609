<mat-card class="mat-elevation-z8 mw-880">
  <evo-busy [busy]="!org || !user">
    <h2>
      <mat-icon>new_label</mat-icon> Create Promo Code
    </h2>
    <dyna-promo-form (valueChange)="valueChange($event)" (statusChange)="statusChange($event)" [readOnlyCode]="false"></dyna-promo-form>
    <div class="flex-1 row space-between">
      <button mat-raised-button color="primary" [disabled]="invalid" (click)="save(value)"><mat-icon>save</mat-icon> Save</button>
      <button mat-stroked-button (click)="back()"><mat-icon>close</mat-icon> Cancel</button>
    </div>
  </evo-busy>
</mat-card>