import { Component, Output, EventEmitter, Input } from '@angular/core';
import { License } from '@evo/generated/admin';
import { oneAppConfig } from '@evo/iso/common';

@Component({
  selector: 'evo-license-list',
  templateUrl: './license-list.component.html',
})
export class LicenseListComponent {
  @Input() licenses: License[];
  @Output() create = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Input() enableCreating = false;
  @Input() enableEditing = false;
  @Input() enableDeleting = false;
  toDelete = {} as any;
  oneAppConfig = oneAppConfig;
  now = new Date();

  initDelete(license: License) {
    this.toDelete[license.id as string] = true;
  }

  cancelDelete(license: License) {
    this.toDelete[license.id as string] = false;
  }

  async deleteLicense(license: License) {
    this.delete.emit(license);
  }
}
