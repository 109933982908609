import { Route, Routes } from '@angular/router';
import { filterRoutesWithoutPath } from '@evo/ui/common';
import { TIconicRoute } from 'libs/ui/common/src/lib/types/iconic-route.type';
import { AdminFacCreatePageComponent } from './pages/admin/admin-fac-create-page/admin-fac-create-page.component';
import { AdminOrgRedirectPageComponent } from './pages/admin/admin-org-redirect-page/admin-org-redirect-page.component';
import { AdminUserEditPageComponent } from './pages/admin/admin-user-edit-page/admin-user-edit-page.component';
import { AdminFacEditPageComponent } from './pages/admin/admin-fac-edit-page/admin-fac-edit-page.component';
import { SystemInvitationsPageComponent } from './pages/system/system-invitations-page/system-invitations-page.component';
import { SystemLicenseCreatePageComponent } from './pages/system/system-license-create-page/system-license-create-page.component';
import { SystemOrgCreatePageComponent } from './pages/system/system-org-create-page/system-org-create-page.component';
import { AdminOrgPageComponent } from './pages/admin/admin-org-page/admin-org-page.component';
import { SystemOrgsPageComponent } from './pages/system/system-orgs-page/system-orgs-page.component';
import { SystemStripeCreatePromoPageComponent } from './pages/system/system-stripe-create-promo-page/system-stripe-create-promo-page.component';
import { SystemStripePromosPageComponent } from './pages/system/system-stripe-promos-page/system-stripe-promos-page.component';
import { AdminUserCreatePageComponent } from './pages/admin/admin-user-create-page/admin-user-create-page.component';
import { SystemUsersPageComponent } from './pages/system/system-users-page/system-users-page.component';
import { UserProfilePageComponent } from './pages/user-profile-page/user-profile-page.component';
import { AdminFacCreateRedirectPageComponent } from './pages/admin/admin-fac-create-redirect-page/admin-fac-create-redirect-page.component';
import { AdminUserCreateRedirectPageComponent } from './pages/admin/admin-user-create-redirect-page/admin-user-create-redirect-page.component';
import { AdminUserAddPageComponent } from './pages/admin/admin-user-add-page/admin-user-add-page.component';
import { AdminUserAddRedirectPageComponent } from './pages/admin/admin-user-add-redirect-page/admin-user-add-redirect-page.component';
import { SystemPostsCreatePageComponent } from './pages/system/system-posts-create-page/system-posts-create-page.component';
import { SystemPostsPageComponent } from './pages/system/system-posts-page/system-posts-page.component';
import { SystemPostsEditPageComponent } from './pages/system/system-posts-edit-page/system-posts-edit-page.component';
import { SystemPostsPreviewPageComponent } from './pages/system/system-posts-preview-page/system-posts-preview-page.component';

const pathMatch = 'full';

export const adminRoutes: TIconicRoute[] = [
  { path: 'admin/org/:id', component: AdminOrgPageComponent },
  {
    path: 'admin/org',
    component: AdminOrgRedirectPageComponent,
    icon: 'assured_workload',
    label: 'Your Organization',
    pathMatch: 'full',
  },
  { path: 'admin/create/fac/:id', component: AdminFacCreatePageComponent },
  {
    path: 'admin/create/fac',
    component: AdminFacCreateRedirectPageComponent,
    icon: 'add_home_work',
    label: 'Add New Facility',
  },
  { path: 'admin/fac/:id', component: AdminFacEditPageComponent },
  { path: 'admin/create/user/:id', component: AdminUserCreatePageComponent },
  {
    path: 'admin/create/user',
    component: AdminUserCreateRedirectPageComponent,
    icon: 'outgoing_mail',
    label: 'Invite New User',
  },
  { path: 'admin/add/user/:id', component: AdminUserAddPageComponent },
  {
    path: 'admin/add/user',
    component: AdminUserAddRedirectPageComponent,
    icon: 'health_and_safety',
    label: 'Directly Add New User',
  },
  { path: 'admin/user/:id', component: AdminUserEditPageComponent },
];

export const sysAdminRoutes: TIconicRoute[] = [
  { path: 'system/post/:id', component: SystemPostsEditPageComponent },
  {
    path: 'system/post-preview/:id',
    component: SystemPostsPreviewPageComponent,
  },

  {
    path: 'system/create/post',
    component: SystemPostsCreatePageComponent,
    icon: 'post_add',
    label: 'Create Post',
  },
  {
    path: 'system/post',
    component: SystemPostsPageComponent,
    icon: 'list_alt',
    label: 'All Posts',
  },
  {
    path: 'system/orgs',
    component: SystemOrgsPageComponent,
    icon: 'domain',
    label: 'All Organizations',
  },
  {
    path: 'system/create/org',
    component: SystemOrgCreatePageComponent,
    label: 'Add New Organization',
    icon: 'domain_add',
  },
  {
    path: 'system/create/license/:id',
    component: SystemLicenseCreatePageComponent,
  },
  {
    path: 'system/create/license',
    component: SystemLicenseCreatePageComponent,
  },
  {
    path: 'system/users',
    component: SystemUsersPageComponent,
    icon: 'group',
    label: 'All Users',
  },
  {
    path: 'system/invitations',
    component: SystemInvitationsPageComponent,
    icon: 'outgoing_mail',
    label: 'Pending Invitations',
  },
  {
    path: 'system/promos',
    component: SystemStripePromosPageComponent,
    icon: 'label',
  },
  {
    path: 'system/promo-create',
    component: SystemStripeCreatePromoPageComponent,
  },
];

export const userRoute: TIconicRoute = {
  path: 'user-profile',
  component: UserProfilePageComponent,
  pathMatch,
  icon: 'account_circle',
  label: 'Your Profile',
} as Route;

export const routes: Routes = [
  { path: '', redirectTo: userRoute.path, pathMatch },
  ...filterRoutesWithoutPath([...adminRoutes, ...sysAdminRoutes]),
  userRoute,
] as any;
