<mat-card class="mat-elevation-z8 mw-880 mb-2">
  <h2>
    <mat-icon>playlist_add</mat-icon> Add License
  </h2>
  <div class="mb-1">
    <evo-dyna-license-form (valueChange)="valueChange($event)"
      (statusChange)="statusChange($event)"></evo-dyna-license-form>
  </div>
  <div class="flex-1 row space-between">
    <button (click)="save()" mat-raised-button color="primary" [disabled]="!dirty || !valid"><mat-icon>save</mat-icon> Save</button>
    <button (click)="back()" mat-stroked-button><mat-icon>close</mat-icon> Cancel</button>
  </div>
</mat-card>

<ng-container *ngIf="org">
  <evo-license-list [enableEditing]="false" [licenses]="org?.licenses"></evo-license-list>
</ng-container>
