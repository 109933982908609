import { Component } from '@angular/core';
import { SnackService } from '@evo/ui/common';
import { BlogPost, DefaultApi } from '@evo/generated/admin';
import { Router } from '@angular/router';

@Component({
  templateUrl: './system-posts-create-page.component.html',
})
export class SystemPostsCreatePageComponent {
  busy = false;
  html = '';
  valid: boolean;
  title = '';
  published = false;

  constructor(
    private snackBar: SnackService,
    private api: DefaultApi,
    private router: Router
  ) {}

  async save(blogPost: BlogPost) {
    this.busy = true;
    await this.api.blogPostsControllerCreate(blogPost);
    this.busy = false;
    this.snackBar.saved();
    this.cancel();
  }

  cancel() {
    this.router.navigateByUrl('system/post');
  }
}
