import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, versionRoute } from '@evo/ui/common';
import { AppShellPageComponent } from './pages/app-shell-page/app-shell-page.component';
import { routes } from './routes.constant';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        versionRoute,
        {
          path: '',
          canActivate: [AuthGuard],
          component: AppShellPageComponent,
          children: [...routes],
        },
      ],
      { initialNavigation: 'enabledBlocking' }
    ),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
