<h1 class="pb-1 flex flex-row justify-between">
  <div>Blog Posts</div>
  <div>
    <a color="primary" mat-mini-fab routerLink="/system/create/post"
      ><mat-icon>add_post</mat-icon></a
    >
  </div>
</h1>
<mat-card class="lite mw-880 mat-elevation-z8">
  <div
    [ngClass]="{ 'opacity-50': !post.published }"
    class="border-gray border-solid border-1 rounded my-1 p-1 flex flex-row justify-between items-center"
    *ngFor="let post of posts"
  >
    <div class="flex flex-row flex-wrap justify-start items-center gap-1">
      <a mat-mini-fab [routerLink]="['/', 'system', 'post', post.id]">
        <mat-icon>edit</mat-icon>
      </a>
      <a mat-mini-fab [routerLink]="['/', 'system', 'post-preview', post.id]">
        <mat-icon>visibility</mat-icon>
      </a>
      <div class="mat-body-2">{{ post.title }}</div>
    </div>
    <div
      class="flex flex-row flex-wrap justify-end items-center gap-2 mat-caption"
    >
      <div *ngIf="post.published">PUBLISHED</div>
      <div *ngIf="!post.published">DRAFT</div>
      <div>{{ post.createdBy?.email }}</div>
      <div>{{ post.createdAt | date }}</div>
    </div>
  </div>
</mat-card>
