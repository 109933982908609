import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  DefaultApi,
  User,
  License,
  Org,
  ReportCategory,
} from '@evo/generated/admin';
import { AppEnum, oneAppConfig } from '@evo/iso/common';
import { IParsedClaims } from '@evo/iso/common';
import {
  formatRegexes,
  IFieldConfig,
  IFieldOption,
} from '@evo/ui/angular-serializable-forms';
import { BaseComponent, ClaimService } from '@evo/ui/common';
import { InvitationEmailResendService } from '../../services/invitation-email-resend.service';

const USER = 'USER';
const ADMIN = 'ADMIN';
const OFFICER = 'OFFICER';

type TViewModel = User & { role?: string };

@Component({
  selector: 'evo-user-form',
  templateUrl: './user-form.component.html',
})
export class UserFormComponent extends BaseComponent {
  @Input() data: User;
  @Input() icon: string;
  @Input() title: string;
  @Input() orgId: string;
  @Input() direct = false;
  @Output() cancel = new EventEmitter<string | undefined>();
  @Output() save = new EventEmitter<User | undefined>();
  showCats = true;
  showFacs = true;
  options: IFieldConfig;
  orgs!: Org[];
  dirty = false;
  claims: IParsedClaims;
  _valid: boolean;
  cats: ReportCategory[];
  categoryField: IFieldOption;
  selectedOrg: Org;
  viewModel: TViewModel;

  get valid() {
    return this._valid;
  }

  constructor(
    public route: ActivatedRoute,
    private api: DefaultApi,
    private claimsSvc: ClaimService,
    public resendService: InvitationEmailResendService
  ) {
    super();
  }

  cancelClicked(orgId?: string) {
    this.cancel.emit(orgId);
  }

  async ngOnInit() {
    this.claims = this.claimsSvc.get();
    const orgId = this.orgId;
    const vm = this.mapUserToViewModel(this.data);
    this.viewModel = { ...vm, orgId };

    this.cats = (await this.api.adminReportCategoryControllerIndex()).data;
    this.categoryField = {
      label: 'Compliance Category Access',
      type: 'select',
      multiple: true,
      options: this.cats.map((c) => ({ display: c.name, value: c.name })),
    };

    if (this.claims.isSysAdmin) {
      this.orgs = (await this.api.adminOrgControllerIndex()).data;
    } else {
      this.orgs = [(await this.api.adminOrgControllerGet(orgId)).data];
    }
    this.configureForm(this.viewModel);
  }

  get isComplianceChecked() {
    return !!this.viewModel?.apps?.includes(AppEnum.COM);
  }

  get isUserComplianceAssigneeOnly() {
    return (
      this.isComplianceChecked &&
      this.viewModel?.role === USER &&
      (!this.viewModel?.categories?.length || !this.viewModel?.facs?.length)
    );
  }

  async configureForm(previousFormValue: TViewModel) {
    if (this.isComplianceChecked) {
      // preselect all categories when a fac is selected if no facs have been selected
      if (
        !previousFormValue?.categories?.length &&
        !previousFormValue?.facs?.length &&
        this.viewModel?.facs?.length
      ) {
        this.viewModel.categories = (this.cats || []).map((c) => c.name);
      }
      // do we need to deselect all cats when all facs have been deselected???
    }

    if (!this.selectedOrg || this.viewModel.orgId !== this.selectedOrg.id) {
      this.selectedOrg = (
        await this.api.adminOrgControllerGet(this.viewModel.orgId as string)
      ).data;

      this.viewModel.apps = (this.viewModel.apps || []).filter((a) =>
        (this.selectedOrg.licenses || []).some((l) => l.appId === a)
      );
      this.viewModel.facs = (this.viewModel.facs || []).filter((fac) =>
        (this.selectedOrg.facilities || []).some((f) => f.id === fac)
      );
      this.showCats = false;
      this.showFacs = true;
    }

    const org = this.selectedOrg;
    const facs = org.facilities ?? [];

    if (
      !this.viewModel?.role ||
      this.viewModel.role === ADMIN ||
      this.viewModel.role === OFFICER
    ) {
      this.viewModel.facs = [];
      this.showFacs = false;
    } else {
      this.showFacs = true;
    }

    if (
      !this.viewModel?.role ||
      this.viewModel.role === ADMIN ||
      this.viewModel.role === OFFICER ||
      !this.viewModel?.apps?.length ||
      !this.viewModel.apps.includes(AppEnum.COM)
    ) {
      this.viewModel.categories = [];
      this.showCats = false;
    } else {
      this.showCats = true;
    }

    const isComplianceAvailable = (org.licenses || [])
      .map((l) => l.appId)
      .includes(AppEnum.COM);
    const hasCompliance = isComplianceAvailable && this.isComplianceChecked;

    if (!hasCompliance && this.viewModel.role === OFFICER) {
      this.viewModel.role = undefined;
    }

    const readOnly = !!this.viewModel.id;

    this.options = {
      fieldOptions: {
        orgId: {
          readOnly,
          label: 'Organization',
          type: 'select',
          validators: [Validators.required],
          options: this.orgs.map((o) => ({ display: o.name, value: o.id })),
        },
        email: {
          readOnly,
          label: 'Email',
          type: 'email',
          validators: [
            Validators.required,
            Validators.pattern(formatRegexes.email),
          ],
        },
        firstName: { label: 'First Name', type: 'text' },
        lastName: { label: 'Last Name', type: 'text' },
        apps: {
          label: org.licenses?.length
            ? 'Apps'
            : 'Apps (This organization currently has no licenses)',
          type: 'select',
          multiple: true,
          options: org?.licenses?.map((value: License) => ({
            display: oneAppConfig[value.appId].label,
            value: value.appId.toString(),
          })),
        },
        role: {
          label: 'User Role',
          type: 'select',
          validators: [Validators.required],
          options: [
            { value: ADMIN, display: 'Admin' },
            hasCompliance
              ? { value: OFFICER, display: 'Compliance Officer' }
              : (undefined as any),
            { value: USER, display: 'User' },
          ].filter(Boolean),
        },
        facs: {
          label: 'Facility Access',
          type: 'select',
          multiple: true,
          options: facs.map((f) => ({ display: f.name, value: f.id })),
        },
        categories: this.categoryField,
      },
      fieldLayout: [
        ['email'],
        ['firstName', 'lastName'],
        ['orgId'],
        this.viewModel?.orgId ? ['apps'] : (undefined as any),
        this.viewModel?.orgId ? ['role'] : (undefined as any),
        this.showFacs ? ['facs'] : (undefined as any),
        this.showCats && hasCompliance ? ['categories'] : (undefined as any),
      ].filter(Boolean),
    };

    if (this.direct) {
      const phone = 'phone';
      this.options.fieldOptions[phone] = {
        readOnly,
        label: 'Phone',
        type: 'phone',
        validators: [
          Validators.required,
          Validators.pattern(formatRegexes.phone),
        ],
      };
      this.options.fieldLayout[0] = [...this.options.fieldLayout[0], phone];
    }
  }

  async saveClicked() {
    this.save.emit(this.mapViewModelToUser(this.viewModel));
  }

  async _valueChange(formValue: TViewModel) {
    this.dirty = true;
    const previousFormValue = this.viewModel;
    this.viewModel = formValue;
    this.configureForm(previousFormValue);
  }

  mapViewModelToUser(vm: TViewModel): User {
    const user: TViewModel = {
      ...vm,
      isAdmin: false,
      isCompOfficer: false,
      role: undefined,
    };

    if (vm.role === ADMIN) {
      user.isAdmin = true;
    } else if (vm.role === OFFICER) {
      user.isCompOfficer = true;
    }

    return user as User;
  }

  mapUserToViewModel(user: User): TViewModel {
    const vm: TViewModel = {
      ...user,
    };

    if (vm.isCompOfficer) {
      vm.role = OFFICER;
    } else if (vm.isAdmin) {
      vm.role = ADMIN;
    } else {
      vm.role = USER;
    }

    return vm;
  }

  _statusChange(valid: boolean) {
    this._valid = valid;
  }
}
