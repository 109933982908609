import { Component, OnInit } from '@angular/core';
import { DefaultApi } from '@evo/generated/admin';
import { IParsedClaims } from '@evo/iso/common';
import { BaseComponent, ClaimService, EvoCookieService } from '@evo/ui/common';
import { TIconicRoute } from 'libs/ui/common/src/lib/types/iconic-route.type';
import { environment } from '../../../environments/environment';
import { sysAdminRoutes, adminRoutes, userRoute } from '../../routes.constant';

const appsRoute: TIconicRoute = {
  label: 'Apps',
  icon: 'apps',
  href: environment.uiUrlAccount,
};

@Component({
  templateUrl: './app-shell-page.component.html',
})
export class AppShellPageComponent extends BaseComponent implements OnInit {
  routes: TIconicRoute[] = [appsRoute, userRoute];
  claims: IParsedClaims;
  uiUrlAccount = environment.uiUrlAccount;

  constructor(
    private api: DefaultApi,
    private cookieService: EvoCookieService,
    private claimSvc: ClaimService
  ) {
    super();
    this.busy$$.next(true);
  }
  async ngOnInit() {
    this.claims = this.claimSvc.get();

    if (this.claims.isAdmin) {
      this.routes = this.routes.concat(...adminRoutes);
    }

    if (this.claims.isSysAdmin) {
      this.routes = this.routes.concat(...sysAdminRoutes);
    }

    this.busy$$.next(false);
  }

  async logout() {
    try {
      await this.api.authControllerLogout();
      this.cookieService.deleteSessionTokens();
      window.location.href = environment.uiUrlAccount;
    } catch (error: any) {
      console.warn(error);
    }
  }
}
