import { Component, OnInit } from '@angular/core';
import { BaseComponent, SnackService } from '@evo/ui/common';
import { DefaultApi } from '@evo/generated/admin';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogPost } from '@evo/generated/admin';

@Component({
  templateUrl: './system-posts-edit-page.component.html',
})
export class SystemPostsEditPageComponent
  extends BaseComponent
  implements OnInit
{
  post: BlogPost;
  postId: string;
  busy = false;

  constructor(
    private snackBar: SnackService,
    private api: DefaultApi,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  async ngOnInit() {
    this.postId = this.route.snapshot.paramMap.get('id') as string;
    this.post = (await this.api.blogPostsControllerGet(this.postId)).data;
  }

  async save(blogPost: BlogPost) {
    this.busy = true;
    await this.api.blogPostsControllerUpdate(this.postId, blogPost);
    this.busy = false;
    this.snackBar.saved();
    this.cancel();
  }

  cancel() {
    this.router.navigateByUrl('system/post');
  }
}
