import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  IDynaForm,
  IFieldConfig,
  template,
} from '@evo/ui/angular-serializable-forms';
import { StripePromo } from '@evo/generated/admin';
import { AppEnum, appsForPurchase, oneAppConfig } from '@evo/iso/common';

@Component({
  selector: 'dyna-promo-form',
  template,
})
export class DynaPromoFormComponent implements IDynaForm<StripePromo>, OnInit {
  ngOnInit(): void {
    this.options = {
      fieldOptions: {
        id: {
          label: 'Code',
          type: 'text',
          validators: [
            Validators.required,
            Validators.minLength(0),
            Validators.maxLength(12),
          ],
          readOnly: this.readOnlyCode,
        },
        apps: {
          label: 'Apps',
          type: 'select',
          multiple: true,
          options: appsForPurchase.map((value: AppEnum) => ({
            display: oneAppConfig[value].label,
            value: value.toString(),
          })),
        },
        active: { label: 'Active', type: 'checkbox' },
        ratio: {
          label: 'Ratio',
          type: 'number',
          validators: [
            Validators.required,
            Validators.min(0),
            Validators.max(1),
          ],
          step: 0.05,
          min: 0,
          max: 1,
        },
      },
      fieldLayout: [['id'], ['apps'], ['active', 'ratio']],
    };
  }
  @Input() data!: StripePromo;
  @Input() readOnlyCode = true;
  options: IFieldConfig;
  @Output() statusChange = new EventEmitter();
  @Output() valueChange = new EventEmitter();
}
