import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { License } from '@evo/generated/admin';
import {
  IDynaForm,
  IFieldConfig,
  template,
} from '@evo/ui/angular-serializable-forms';
import { oneAppConfig, appsForPurchase } from '@evo/iso/common';
import { Validators } from '@angular/forms';

const type = 'number';
const validators = [Validators.required, Validators.min(1), Validators.max(99)];

@Component({
  selector: 'evo-dyna-license-form',
  template,
})
export class DynaLicenseFormComponent implements OnInit, IDynaForm<License> {
  @Input() data!: License;
  @Output() valueChange = new EventEmitter();
  @Output() statusChange = new EventEmitter();
  options: IFieldConfig;

  async ngOnInit() {
    this.options = {
      fieldOptions: {
        appId: {
          validators: [Validators.required],
          label: 'App',
          type: 'select',
          options: appsForPurchase.map((value) => ({
            display: oneAppConfig[value].label,
            value,
          })),
        },
        startDate: {
          validators: [Validators.required],
          label: 'Start Date',
          type: 'date',
        },
        endDate: {
          validators: [Validators.required],
          label: 'End Date',
          type: 'date',
        },
        maxAdmins: { label: 'Max admins', type, validators },
        maxFacs: { label: 'Max facs', type, validators },
        maxUsers: { label: 'Max users', type, validators },
      },
      fieldLayout: [
        ['appId'],
        ['startDate'],
        ['endDate'],
        ['maxAdmins'],
        ['maxFacs'],
        ['maxUsers'],
      ],
    };
  }
}
