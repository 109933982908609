import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent, SnackService } from '@evo/ui/common';
import { DefaultApi, StripePromo, Org, User } from '@evo/generated/admin';
import { Router } from '@angular/router';

@Component({
  templateUrl: './system-stripe-promos-page.component.html',
})
export class SystemStripePromosPageComponent extends BaseComponent implements OnInit, OnDestroy {
  user: User;
  org: Org;
  stripePromos: StripePromo[];

  constructor(
    private api: DefaultApi,
    private snackBar: SnackService,
    private router: Router
  ) {
    super();
  }

  async valueChange ($event: StripePromo) {
    console.warn('valueChange', $event);
    await this.api.systemStripePromoControllerPut($event.id, $event);
    this.snackBar.saved();
  }
  statusChange ($event: any) { }

  ngOnDestroy(): void {
  }

  async ngOnInit() {
    this.user = (await this.api.userControllerGet()).data;
    this.org = this.user.org as Org;
    await this.loadStripePromos();
  }

  async loadStripePromos() {;
    this.stripePromos = (await this.api.systemStripePromoControllerIndex()).data;
  }

  create () {
    this.router.navigate(['system', 'promo-create']);
  }
}
