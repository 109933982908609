import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DefaultApi, Fac, Org, User } from '@evo/generated/admin';
import { timezones } from '@evo/iso/common';
import {
  formatRegexes,
  IDynaForm,
  IFieldConfig,
  template,
} from '@evo/ui/angular-serializable-forms';

@Component({
  selector: 'evo-user-profile-form',
  template,
})
export class UserProfileFormComponent implements OnInit, IDynaForm<User> {
  @Input() data!: User;
  @Input() plusOnePhone = false;
  @Output() valueChange = new EventEmitter();
  @Output() statusChange = new EventEmitter();
  org: Org;
  facs: Fac[] | undefined;

  constructor(private fb: FormBuilder, private api: DefaultApi) {}
  options: IFieldConfig;

  async ngOnInit() {
    this.org = this.data.org as Org;
    this.facs = this.org.facilities ?? [];
    this.options = {
      fieldOptions: {
        timezone: {
          label: 'Time Zone',
          type: 'select',
          validators: [Validators.required],
          options: timezones.map((value) => ({ value, display: value })),
        },
        email: {
          label: 'Email',
          type: 'text',
          readOnly: true,
          validators: [
            Validators.required,
            Validators.pattern(formatRegexes.email),
          ],
        },
        phone: {
          label: 'Phone',
          type: 'text',
          readOnly: true,
          validators: [
            Validators.required,
            Validators.pattern(
              this.plusOnePhone
                ? formatRegexes.phonePlusOne
                : formatRegexes.phone
            ),
          ],
        },
        firstName: { label: 'First Name', type: 'text' },
        lastName: { label: 'Last Name', type: 'text' },
      },
      fieldLayout: [
        ['email', 'phone'],
        ['firstName', 'lastName'],
        ['timezone'],
      ],
    };
  }
}
