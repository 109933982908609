<h1>Edit Blog Post</h1>

<evo-busy [busy]="!post"></evo-busy>
<evo-blog-post-form
  *ngIf="post"
  [html]="post?.html"
  [title]="post?.title"
  [published]="post?.published"
  (save)="save($event)"
  (cancel)="cancel()"
  [busy]="busy"
></evo-blog-post-form>
