import { Component, OnInit } from '@angular/core';
import {
  BaseComponent,
  ClaimService,
  JsonDetailComponent,
  SnackService,
} from '@evo/ui/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultApi, User } from '@evo/generated/admin';
import { MatDialog } from '@angular/material/dialog';

@Component({
  template:
    '<evo-user-form [orgId]="orgId" [direct]="true" icon="health_and_safety" title="Directly Add New User" (save)="save($event)" (cancel)="cancel($event)"></evo-user-form>',
})
export class AdminUserAddPageComponent extends BaseComponent implements OnInit {
  orgId: string;
  constructor(
    private router: Router,
    private claimsSvc: ClaimService,
    public route: ActivatedRoute,
    private snackBar: SnackService,
    private api: DefaultApi,
    private dialog: MatDialog
  ) {
    super();
  }

  async ngOnInit() {
    this.orgId = this.route.snapshot.paramMap.get('id') as string;
  }

  async cancel(_value: User) {
    console.warn({ _value });
  }

  async save(value: User) {
    try {
      this.busy$$.next(true);
      const newUser = (await this.api.adminUserControllerPost(value)).data;
      this.dialog.open(JsonDetailComponent, {
        data: {
          title: 'New User Account Details',
          email: newUser.email,
          password: (newUser.data as any)?.pw,
        },
      });
      this.snackBar.saved();
    } catch (error: any) {
      console.warn({ error });
      this.snackBar.oops();
    }
    this.busy$$.next(false);
  }

  done(newUser: User) {
    if (newUser.id) {
      this.router.navigateByUrl(`admin/user/${newUser.id}`);
    }
  }
}
