<mat-card class="lite mw-880 mat-elevation-z8">
  <evo-blog-title-form
    [data]="{ title: title, published: published }"
    (statusChange)="statusChange($event)"
    (valueChange)="valueChange($event)"
  ></evo-blog-title-form>
  <div class="my-2">
    <evo-ck-editor-input
      [data]="html"
      (change)="contentChange($event)"
    ></evo-ck-editor-input>
  </div>
  <div class="flex flex-row justify-between">
    <button mat-stroked-button (click)="cancel.emit()">
      <mat-icon>cancel</mat-icon> Cancel
    </button>
    <evo-button
      [disabled]="!valid || !_html"
      color="primary"
      [busy]="busy"
      (click)="_save()"
      ><mat-icon>save</mat-icon> Save</evo-button
    >
  </div>
</mat-card>
