import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subject, Subscription, debounceTime, tap } from 'rxjs';

@Component({
  selector: 'evo-ck-editor-input',
  templateUrl: './ck-editor-input.component.html',
})
export class CkEditorInputComponent implements OnInit, OnDestroy {
  @Input() data = '';
  @Output() change = new EventEmitter();
  readonly Editor = ClassicEditor;
  readonly change$$ = new Subject();
  readonly change$ = this.change$$.asObservable().pipe(
    debounceTime(500),
    tap((_) => this.change.emit(_))
  );
  private sub: Subscription;

  ngOnInit(): void {
    this.change$$.next(this.data);
    this.sub = this.change$.subscribe();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onChange($event: any) {
    this.change$$.next($event.editor.getData());
  }
}
