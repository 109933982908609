import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { BlogPost } from '@evo/generated/admin';
import { DefaultApi } from '@evo/generated/admin';
import {
  IDynaForm,
  IFieldConfig,
  template,
} from '@evo/ui/angular-serializable-forms';

type TData = Pick<BlogPost, 'title' | 'published'>;

@Component({
  selector: 'evo-blog-title-form',
  template,
})
export class BlogTitleFormComponent
  implements OnInit, OnChanges, IDynaForm<TData>
{
  @Input() data!: TData;
  @Output() valueChange = new EventEmitter();
  @Output() statusChange = new EventEmitter();
  options: IFieldConfig;

  constructor(private api: DefaultApi) {}

  async ngOnInit() {
    this.init(this.data);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue) {
      this.init(changes['data'].currentValue);
    }
  }

  private async init(data: TData) {
    this.data = data;

    this.options = {
      fieldOptions: {
        title: {
          label: 'Title',
          type: 'text',
          validators: [Validators.required],
        },
        published: {
          label: 'Publish',
          type: 'slide-toggle',
        },
      },
      fieldLayout: [['title'], ['published']],
    };
  }
}
