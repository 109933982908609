<evo-busy [busy]="!post">
  <div class="mw-880 mt-2 flex flex-row justify-between">
    <a color="accent" mat-raised-button routerLink="/system/post"
      ><mat-icon>arrow_left</mat-icon> All Posts</a
    >
    <a
      color="primary"
      mat-raised-button
      [routerLink]="['/', 'system', 'post', postId]"
      ><mat-icon>edit</mat-icon> Edit Post</a
    >
  </div>
  <div class="mt-2">
    <evo-ck-editor-output
      [html]="post?.html"
      [title]="post?.title"
      [createdAt]="post?.createdAt"
    ></evo-ck-editor-output>
  </div>
</evo-busy>
