import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultApi, Fac, Org } from '@evo/generated/admin';
import { IParsedClaims } from '@evo/iso/common';
import { IDynaFormConsumer } from '@evo/ui/angular-serializable-forms';
import { BaseComponent, ClaimService, SnackService } from '@evo/ui/common';

@Component({
  templateUrl: './admin-fac-edit-page.component.html',
})
export class AdminFacEditPageComponent
  extends BaseComponent
  implements IDynaFormConsumer<Fac>
{
  data: Fac;
  updatedFac: Fac = {} as any;
  dirty = false;
  valid = false;
  claims: IParsedClaims;
  orgs: Org[];

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private api: DefaultApi,
    private snackBar: SnackService,
    private claimsSvc: ClaimService
  ) {
    super();
  }

  async ngOnInit() {
    this.claims = this.claimsSvc.get();
    const facId = this.route.snapshot.paramMap.get('id');
    this.data = (await this.api.adminFacControllerGet(facId as string)).data;
    this.orgs = [(await this.api.adminOrgControllerGet(this.data.orgId)).data];
  }

  back() {
    this.router.navigateByUrl(`admin/org/${this.updatedFac.orgId}`);
  }

  async save() {
    try {
      this.busy$$.next(true);
      await this.api.adminFacControllerPut(this.data.id, this.updatedFac);
      this.dirty = false;
      this.snackBar.saved();
      this.back();
      this.busy$$.next(false);
    } catch (error) {
      this.snackBar.oops();
    }
  }

  valueChange(updatedFac: Fac) {
    this.dirty = true;
    this.updatedFac = updatedFac;
  }

  statusChange(valid: boolean) {
    this.valid = valid;
  }
}
