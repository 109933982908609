<mat-card class="mat-elevation-z8 mw-880 mb-2">
  <h2>
    <div class="flex-1 row space-between">
      <div><mat-icon>playlist_add_check</mat-icon> Active Licenses</div>
      <button
        class="mb-1"
        *ngIf="enableCreating"
        (click)="create.emit()"
        color="primary"
        mat-raised-button
      >
        <mat-icon>playlist_add</mat-icon>
      </button>
    </div>
  </h2>
  <evo-busy [busy]="!licenses">
    <ng-container *ngIf="licenses?.length">
      <mat-card class="mat-elevation-z0 mb-4" *ngFor="let lic of licenses">
        <h3 class="row space-between flex-1 pb-1">
          <div class="flex-1">
            <div *ngIf="!toDelete[lic.id]">
              <button
                mat-raised-button
                color="primary"
                (click)="initDelete(lic)"
                *ngIf="enableDeleting"
                [disabled]="lic.stripePaymentId"
              >
                <mat-icon>close</mat-icon>
              </button>
              {{ oneAppConfig[lic.appId].label }}
              <span
                *ngIf="enableDeleting && lic.stripePaymentId"
                class="mat-caption"
                >(Purchased)</span
              >
            </div>
            <div *ngIf="toDelete[lic.id]">
              <button
                mat-stroked-button
                color="accent"
                (click)="cancelDelete(lic)"
              >
                <mat-icon>close</mat-icon>
              </button>
              <button
                style="float: right"
                mat-raised-button
                color="warn"
                (click)="deleteLicense(lic)"
              >
                <mat-icon>delete</mat-icon> {{ oneAppConfig[lic.appId].label }}
              </button>
            </div>
          </div>
          <div>
            <span class="pl-1">
              <mat-icon>security</mat-icon> {{ lic.maxAdmins }}
            </span>
            <span class="pl-1">
              <mat-icon>home_work</mat-icon> {{ lic.maxFacs }}
            </span>
            <span class="pl-1">
              <mat-icon>group</mat-icon> {{ lic.maxUsers }}
            </span>
          </div>
        </h3>
        <div class="row space-between flex-1">
          <div>{{ lic.startDate | date }}</div>
          <div>{{ lic.endDate | date }}</div>
        </div>
      </mat-card>
    </ng-container>
  </evo-busy>
  <div class="my-1" *ngIf="!licenses?.length">No active licenses.</div>
</mat-card>
