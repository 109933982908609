import { Component, Output, EventEmitter, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { Fac } from "@evo/generated/admin";

@Component({
    selector: 'evo-fac-list',
    templateUrl: './fac-list.component.html'
})
export class FacListComponent implements OnInit, OnChanges {
    @Input() facs!: Fac[];
    @Output() delete = new EventEmitter();
    @Output() edit = new EventEmitter();
    @Output() create = new EventEmitter();
    @Input() enableEditing = false;
    @Input() enableCreating = false;
    @Input() enableDeleting = false;
    toDelete = {} as any;
    filteredFacs!: Fac[];
    filter: any;

    ngOnChanges(changes: SimpleChanges): void {
        this.filterChange('');
    }

    filterChange ($event: any) {
        this.filter = $event;
        this.filteredFacs = $event.filter ? this.facs.filter(u => u.name?.toLowerCase().includes($event.filter?.toLowerCase())) : this.facs;
    }

    ngOnInit(): void {
        this.filteredFacs = this.facs;
    }

    initDelete(fac: Fac) {
        this.toDelete[fac.id as string] = true;
    }

    cancelDelete(fac: Fac) {
        this.toDelete[fac.id as string] = false;
    }
}