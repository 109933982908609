import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { DefaultApi, Fac, Org } from '@evo/generated/admin';
import {
  formatRegexes,
  IDynaForm,
  IFieldConfig,
  template,
} from '@evo/ui/angular-serializable-forms';

@Component({
  selector: 'evo-dyna-fac-form',
  template,
})
export class DynaFacFormComponent implements OnInit, OnChanges, IDynaForm<Fac> {
  @Input() data!: Fac;
  @Input() orgs: Org[];
  @Output() valueChange = new EventEmitter();
  @Output() statusChange = new EventEmitter();
  fac: Fac;
  options: IFieldConfig;

  constructor(private api: DefaultApi) {}

  async ngOnInit() {
    this.init(this.data);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue) {
      this.init(changes['data'].currentValue);
    }
  }

  private async init(data: Fac) {
    this.data = { ...this.data, orgId: this.data.orgId };

    this.options = {
      fieldOptions: {
        orgId: {
          label: 'Organization',
          type: 'select',
          validators: [Validators.required],
          options: this.orgs.map((org) => ({
            display: org.name,
            value: org.id,
          })),
        },
        name: {
          label: 'Name',
          type: 'text',
          validators: [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(80),
          ],
        },
        phone: {
          label: 'Phone',
          type: 'text',
          validators: [Validators.pattern(formatRegexes.phone)],
        },
        street: { label: 'Street', type: 'text' },
        city: { label: 'City', type: 'text' },
        state: { label: 'State', type: 'text' },
        zip: {
          label: 'Zip',
          type: 'text',
          validators: [Validators.pattern(formatRegexes.zip)],
        },
        contactName: { label: 'Primary Contact Info', type: 'text' },
      },
      fieldLayout: [
        ['orgId'],
        ['name'],
        ['phone', 'contactName'],
        ['street', 'city'],
        ['state', 'zip'],
      ],
    };
  }
}
