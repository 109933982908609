import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultApi, User } from '@evo/generated/admin';
import { BaseComponent, SnackService } from '@evo/ui/common';

@Component({
  template:
    '<evo-user-form *ngIf="data" [orgId]="orgId" icon="edit" title="Edit User" (save)="save($event)" [data]="data" (cancel)="cancel($event)"></evo-user-form>',
})
export class AdminUserEditPageComponent extends BaseComponent {
  data: User;
  orgId: string;
  userId: string;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private api: DefaultApi,
    private snackBar: SnackService
  ) {
    super();
  }

  async ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get('id') as string;
    this.data = (await this.api.adminUserControllerGet(this.userId)).data;
    this.orgId = this.data.orgId;
  }

  async cancel(_value: User) {
    console.warn({ _value });
  }

  async save(value: User) {
    try {
      this.busy$$.next(true);
      await this.api.adminUserControllerPut(this.data.id, value);
      this.snackBar.saved();
    } catch (error: any) {
      console.warn({ error });
      this.snackBar.oops();
    }
    this.busy$$.next(false);
  }
}
