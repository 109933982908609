import { Component, OnInit } from '@angular/core';
import { BaseComponent, SnackService } from '@evo/ui/common';
import { DefaultApi, License, Org, User } from '@evo/generated/admin';
import { ActivatedRoute, Router } from '@angular/router';
import { oneAppConfig } from '@evo/iso/common';

@Component({
  templateUrl: './system-license-create-page.component.html',
})
export class SystemLicenseCreatePageComponent
  extends BaseComponent
  implements OnInit
{
  oneAppConfig = oneAppConfig;
  orgId: string;
  user: User;
  org: Org;
  valid = false;
  dirty = false;
  newLicense: License = {} as any;

  constructor(
    private snackBar: SnackService,
    private api: DefaultApi,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  async ngOnInit() {
    this.orgId = this.route.snapshot.paramMap.get('id') as string;
    if (this.orgId) {
      this.org = (await this.api.adminOrgControllerGet(this.orgId)).data;
    } else {
      this.router.navigate(['system', 'orgs']);
    }
  }

  statusChange(valid: boolean) {
    this.valid = valid;
  }

  async valueChange(license: License) {
    this.newLicense = { ...license, orgId: this.orgId };
    this.dirty = true;
  }

  async save() {
    if (this.valid) {
      this.busy$$.next(true);
      await this.api.systemLicenseControllerPost({
        ...this.newLicense,
        orgId: this.orgId,
      });
      this.dirty = false;
      this.newLicense = { orgId: this.orgId } as any;
      this.snackBar.saved();
      this.back();
    }
  }

  back() {
    this.router.navigateByUrl(`admin/org/${this.orgId}`);
  }
}
