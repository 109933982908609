import { Component, Input } from '@angular/core';

@Component({
  selector: 'evo-ck-editor-output',
  templateUrl: './ck-editor-output.component.html',
  styleUrls: ['./ck-editor-output.component.scss'],
})
export class CkEditorOutputComponent {
  @Input() html: string;
  @Input() title: string;
  @Input() createdAt: Date;
}
