import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { AppData } from '@evo/generated/admin';
import { IDynaForm, IFieldConfig, template } from '@evo/ui/angular-serializable-forms';

const type = 'number';
const validators = [Validators.min(1), Validators.max(24)];

@Component({
  selector: 'evo-quantity-form',
  template
})
export class QuantityFormComponent implements OnInit, OnChanges, IDynaForm<AppData['quantityConfig']> {
  @Input() data: AppData['quantityConfig'];
  @Output() valueChange = new EventEmitter();
  @Output() statusChange = new EventEmitter();
  options: IFieldConfig;

  async ngOnInit() {
    this.init(this.data);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue) {
      this.init(changes['data'].currentValue);
    }
  }

  private async init(data: AppData['quantityConfig']) {

    this.options = {
      fieldOptions: {
        maxAdmins: { label: 'Max admins per license', type, validators },
        maxFacs: { label: 'Max facs per license', type, validators },
        maxUsers: { label: 'Max users per license', type, validators },
      },
      fieldLayout: [
        ['maxAdmins'],
        ['maxFacs'],
        ['maxUsers'],
      ]
    };
  }
}
