<mat-card class="mat-elevation-z8 mw-880">
  <h2 class="flex-1 row space-between">
    <div><mat-icon>home_work</mat-icon> Facilities</div>
    <button
      *ngIf="enableCreating"
      class="mb-1"
      (click)="create.emit()"
      mat-raised-button
      color="primary"
    >
      <mat-icon>add_home_work</mat-icon>
    </button>
  </h2>
  <evo-input-filter-form
    (valueChange)="filterChange($event)"
  ></evo-input-filter-form>
  <div class="py-1 px-0 border-box mw-1" *ngIf="!filter && !facs?.length">
    There are no facilities for this organization.
  </div>
  <div
    class="py-1 px-0 border-box mw-1"
    *ngIf="filter && !filteredFacs?.length"
  >
    No facilities match that filter criteria.
  </div>
  <div class="py-1 px-0 border-box mw-1" *ngFor="let fac of filteredFacs">
    <div class="row">
      <ng-container *ngIf="!toDelete[fac.id]">
        <div class="flex-none" *ngIf="enableDeleting">
          <button mat-raised-button (click)="initDelete(fac)" color="warn">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="column items-start justify-start pl-1">
          <div class="row items-start">
            <ng-container *ngIf="enableEditing">
              <button
                mat-stroked-button
                color="primary"
                (click)="edit.emit(fac)"
              >
                {{ fac.name }}
              </button>
            </ng-container>
            <ng-container *ngIf="!enableEditing">
              {{ fac.name }}
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="enableEditing && toDelete[fac.id]">
        <div>
          <button mat-stroked-button (click)="cancelDelete(fac)" color="accent">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="column items-start justify-start pl-1">
          <div class="row items-start">
            <button mat-raised-button (click)="delete.emit(fac)" color="warn">
              <mat-icon>delete</mat-icon> {{ fac.name }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</mat-card>
