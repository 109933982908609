import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { User } from '@evo/generated/admin';

@Component({
  selector: 'evo-user-list',
  templateUrl: './user-list.component.html',
})
export class UserListComponent implements OnInit, OnChanges {
  @Input() users!: User[];
  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() create = new EventEmitter();
  @Input() enableEditing = false;
  @Input() enableCreating = false;
  @Input() enableDeleting = false;
  @Input() enableFiltering = true;
  toDelete = {} as any;
  filteredUsers!: User[];
  filter: any;

  filterChange($event: any) {
    this.filter = $event;
    this.filteredUsers = $event.filter
      ? this.users.filter((u) =>
          u.email?.toLowerCase().includes($event.filter?.toLowerCase())
        )
      : this.users;
  }

  ngOnInit(): void {
    this.filteredUsers = this.users;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterChange('');
  }

  initDelete(user: User) {
    this.toDelete[user.id as string] = true;
  }

  cancelDelete(user: User) {
    this.toDelete[user.id as string] = false;
  }

  async deleteUser(user: User) {
    this.delete.emit(user);
  }
}
