import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent, SnackService } from '@evo/ui/common';
import { DefaultApi, Org, User } from '@evo/generated/admin';

@Component({
  templateUrl: './user-profile-page.component.html',
})
export class UserProfilePageComponent extends BaseComponent implements OnInit {
  user: User;
  org: Org;
  updatedUser: User;
  valid: boolean;
  dirty: boolean;

  constructor(
    private snackBar: SnackService,
    private api: DefaultApi
  ) {
    super();
  }

  valueChange(user: User) {
    this.updatedUser = user;
    this.dirty = true;
  }

  statusChange(valid: boolean) {
    this.valid = valid;
  }

  async ngOnInit() {
    this.user = (await this.api.userControllerGet()).data;
    this.org = this.user.org as Org;
  }

  async save() {
    this.busy$$.next(true);
    try {
      await this.api.userControllerPut(this.updatedUser);
      this.busy$$.next(false);
      this.valid = false;
      this.snackBar.open('Saved!');
    } catch (error) {
      this.snackBar.oops();
    }
  }
}
