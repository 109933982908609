import { Component, OnInit } from '@angular/core';
import { BaseComponent, ClaimService } from '@evo/ui/common';
import { Router } from '@angular/router';

@Component({
  template: '<evo-busy [busy]="true"></evo-busy>',
})
export class AdminUserAddRedirectPageComponent
  extends BaseComponent
  implements OnInit
{
  constructor(private router: Router, private claimsSvc: ClaimService) {
    super();
  }

  async ngOnInit() {
    this.router.navigateByUrl(`admin/add/user/${this.claimsSvc.get().orgId}`);
  }
}
