<div class="ck-editor-output mw-880 p-2 lite rounded mat-elevation-z8">
  <h2 class="flex flex-row flex-wrap justify-between mb-2">
    <div>
      {{ title }}
    </div>
    <div class="opacity-50" *ngIf="createdAt">
      {{ createdAt | date }}
    </div>
  </h2>
  <ng-container *ngIf="html">
    <div [innerHTML]="html | trust"></div>
  </ng-container>
</div>
