import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CkEditorInputComponent } from './components/ck-editor-input/ck-editor-input.component';
import { CkEditorOutputComponent } from './components/ck-editor-output/ck-editor-output.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TrustPipe } from './pipes/trust.pipe';

@NgModule({
  declarations: [CkEditorInputComponent, CkEditorOutputComponent, TrustPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    BrowserModule,
  ],
  exports: [CkEditorInputComponent, CkEditorOutputComponent, CKEditorModule],
})
export class UiCkEditorModule {}
