import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'evo-blog-post-form',
  templateUrl: './blog-post-form.component.html',
})
export class BlogPostFormComponent {
  @Input() html: string;
  @Input() title = '';
  @Input() published = false;
  @Input() busy = false;
  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();
  valid: boolean;
  _html: string;
  _title: string;

  async ngOnInit() {
    this._html = this.html;
    this._title = this.title;

    if (this.title) {
      this.valid = true;
    }
  }

  async _save() {
    this.save.emit({
      html: this._html,
      title: this._title,
      createdAt: undefined as any,
      published: this.published,
      id: undefined as any,
      createdBy: undefined as any,
      createdById: undefined as any,
    });
  }

  statusChange(valid: boolean) {
    this.valid = valid;
  }

  valueChange(value: { title: string; published: boolean }) {
    if (value) {
      this._title = value.title;
      this.published = !!value.published;
    }
  }

  contentChange($event: any) {
    console.warn($event);
    this._html = $event;
  }
}
