import { NgModule } from '@angular/core';

import { RoutingModule } from './routing.module';
import {
  adminApiProvider,
  MaterialModule,
  UiCommonModule,
} from '@evo/ui/common';

import { environment } from '../environments/environment';

import { AdminOrgRedirectPageComponent } from './pages/admin/admin-org-redirect-page/admin-org-redirect-page.component';
import { AdminUserEditPageComponent } from './pages/admin/admin-user-edit-page/admin-user-edit-page.component';

import { AppShellPageComponent } from './pages/app-shell-page/app-shell-page.component';
import { UserProfilePageComponent } from './pages/user-profile-page/user-profile-page.component';

import { SystemInvitationsPageComponent } from './pages/system/system-invitations-page/system-invitations-page.component';
import { SystemUsersPageComponent } from './pages/system/system-users-page/system-users-page.component';
import { SystemStripePromosPageComponent } from './pages/system/system-stripe-promos-page/system-stripe-promos-page.component';
import { SystemStripeCreatePromoPageComponent } from './pages/system/system-stripe-create-promo-page/system-stripe-create-promo-page.component';

import { AppComponent } from './components/app/app.component';
import { AppFormComponent } from './components/app-form/app-form.component';
import { DynaFacFormComponent } from './components/dyna-fac-form/dyna-fac-form.component';
import { DynaOrgFormComponent } from './components/dyna-org-form/dyna-org-form.component';
import { DynaPromoFormComponent } from './components/dyna-promo-form/dyna-promo-form.component';
import { FacListComponent } from './components/fac-list/fac-list.component';
import { UserProfileFormComponent } from './components/user-profile-form/user-profile-form.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { SystemOrgsPageComponent } from './pages/system/system-orgs-page/system-orgs-page.component';
import { OrgListComponent } from './components/org-list/org-list.component';
import { AdminOrgPageComponent } from './pages/admin/admin-org-page/admin-org-page.component';
import { AdminUserCreatePageComponent } from './pages/admin/admin-user-create-page/admin-user-create-page.component';
import { SystemOrgCreatePageComponent } from './pages/system/system-org-create-page/system-org-create-page.component';
import { DynaLicenseFormComponent } from './components/dyna-license-form/dyna-license-form.component';
import { AdminFacEditPageComponent } from './pages/admin/admin-fac-edit-page/admin-fac-edit-page.component';
import { LicenseListComponent } from './components/license-list/license-list.component';
import { SystemLicenseCreatePageComponent } from './pages/system/system-license-create-page/system-license-create-page.component';
import { QuantityFormComponent } from './components/dyna-quantity-form/dyna-quantity-form.component';
import { AdminFacCreatePageComponent } from './pages/admin/admin-fac-create-page/admin-fac-create-page.component';
import { AdminFacCreateRedirectPageComponent } from './pages/admin/admin-fac-create-redirect-page/admin-fac-create-redirect-page.component';
import { AdminUserCreateRedirectPageComponent } from './pages/admin/admin-user-create-redirect-page/admin-user-create-redirect-page.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { AdminUserAddPageComponent } from './pages/admin/admin-user-add-page/admin-user-add-page.component';
import { AdminUserAddRedirectPageComponent } from './pages/admin/admin-user-add-redirect-page/admin-user-add-redirect-page.component';
import { SystemPostsCreatePageComponent } from './pages/system/system-posts-create-page/system-posts-create-page.component';
import { SystemPostsPageComponent } from './pages/system/system-posts-page/system-posts-page.component';
import { UiCkEditorModule } from '@evo/ui/ck-editor';
import { SystemPostsEditPageComponent } from './pages/system/system-posts-edit-page/system-posts-edit-page.component';
import { BlogTitleFormComponent } from './components/blog-title-form/blog-title-form.component';
import { BlogPostFormComponent } from './components/blog-post-form/blog-post-form.component';
import { SystemPostsPreviewPageComponent } from './pages/system/system-posts-preview-page/system-posts-preview-page.component';

const components = [
  AppComponent,
  AppFormComponent,
  BlogTitleFormComponent,
  BlogPostFormComponent,
  DynaFacFormComponent,
  DynaLicenseFormComponent,
  DynaOrgFormComponent,
  DynaPromoFormComponent,
  FacListComponent,
  LicenseListComponent,
  OrgListComponent,
  QuantityFormComponent,
  UserProfileFormComponent,
  UserListComponent,
  UserFormComponent,
];

const adminPages = [
  AdminFacCreatePageComponent,
  AdminFacCreateRedirectPageComponent,
  AdminFacEditPageComponent,
  AdminOrgPageComponent,
  AdminOrgRedirectPageComponent,
  AdminUserAddPageComponent,
  AdminUserAddRedirectPageComponent,
  AdminUserCreateRedirectPageComponent,
  AdminUserEditPageComponent,
];

const systemPages = [
  SystemInvitationsPageComponent,
  SystemLicenseCreatePageComponent,
  SystemOrgCreatePageComponent,
  SystemOrgsPageComponent,
  SystemPostsCreatePageComponent,
  SystemPostsEditPageComponent,
  SystemPostsPageComponent,
  SystemPostsPreviewPageComponent,
  SystemStripeCreatePromoPageComponent,
  SystemStripePromosPageComponent,
  AdminUserCreatePageComponent,
  SystemUsersPageComponent,
];

const pages = [
  AppShellPageComponent,
  UserProfilePageComponent,
  ...adminPages,
  ...systemPages,
];

@NgModule({
  declarations: [...components, ...pages],
  imports: [
    UiCommonModule.configure(environment),
    MaterialModule,
    RoutingModule,
    UiCkEditorModule,
  ],
  providers: [adminApiProvider(environment)],
  exports: [...pages, UiCkEditorModule],
  bootstrap: [AppComponent],
})
export class UiAdminModule {}
