<mat-card class="mw-880 mat-elevation-z8">
  <h2>Create Facility</h2>
  <evo-busy [busy]="!data || !claims">
    <ng-container *ngIf="claims && data && orgs?.length">
      <evo-dyna-fac-form
        (valueChange)="valueChange($event)"
        (statusChange)="statusChange($event)"
        [data]="data"
        [orgs]="orgs"
      ></evo-dyna-fac-form>
    </ng-container>

    <evo-busy [busy]="busy$ | async">
      <div class="flex-1 row space-between">
        <button
          color="primary"
          class="mt-2"
          mat-raised-button
          (click)="save()"
          [disabled]="!valid || !dirty"
        >
          <mat-icon>save</mat-icon> Save
        </button>
        <button class="mt-2" mat-stroked-button (click)="back()">
          <mat-icon>close</mat-icon> Cancel
        </button>
      </div>
    </evo-busy>
  </evo-busy>
</mat-card>
