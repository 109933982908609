<evo-busy [busy]="!claims || !orgs?.length">
  <mat-card class="mw-880 mat-elevation-z8 mb-2">
    <h2>
      <mat-icon>{{ icon }}</mat-icon> {{ title }}
    </h2>
    <ng-container *ngIf="claims">
      <dyna-form
        *ngIf="options"
        [data]="viewModel"
        [uiOptions]="options"
        (statusChange)="_statusChange($event)"
        (valueChange)="_valueChange($event)"
        [delay]="1500"
      ></dyna-form>
    </ng-container>
    <div
      class="mat-body-2 text-center dark rounded p-2"
      *ngIf="isUserComplianceAssigneeOnly"
    >
      This user will only have access to reports that are directly assigned to
      them.
    </div>
    <evo-busy [busy]="busy$ | async">
      <div class="flex-1 row space-between">
        <button
          color="primary"
          class="mt-2"
          mat-raised-button
          (click)="saveClicked()"
          [disabled]="!valid || !dirty"
        >
          <mat-icon>save</mat-icon> Save
        </button>
        <button class="mt-2" mat-stroked-button (click)="cancelClicked()">
          <mat-icon>close</mat-icon> Cancel
        </button>
      </div>
    </evo-busy>
  </mat-card>

  <evo-invitation-list
    [invitations]="selectedOrg?.invitations || []"
    [enableResending]="true"
    (resend)="resendService.resend($event.email)"
  ></evo-invitation-list>
  <div class="mt-2">
    <evo-user-list [users]="selectedOrg?.users || []"></evo-user-list>
  </div>
</evo-busy>
