import { Injectable } from '@angular/core';
import { EvoCookieService } from './evo-cookie.service';
import { IParsedClaims, parseClaims } from '@evo/iso/common';
import { Observable, shareReplay, startWith } from 'rxjs';
import { decode } from '../functions/decode.function';

@Injectable({ providedIn: 'root' })
export class ClaimService {
  constructor(private cookieService: EvoCookieService) {}

  get$ = new Observable<any>().pipe(startWith(this.get()), shareReplay());

  get(): IParsedClaims {
    const idToken = this.cookieService.getIdToken();

    return parseClaims(idToken ? decode(idToken) : undefined);
  }
}
