import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultApi, Fac, Invitation, Org, User } from '@evo/generated/admin';
import { IParsedClaims } from '@evo/iso/common';
import { BaseComponent, ClaimService, SnackService } from '@evo/ui/common';

@Component({
  templateUrl: './admin-fac-create-page.component.html',
})
export class AdminFacCreatePageComponent extends BaseComponent {
  dirty = false;
  orgs: Org[];
  data: Fac = {} as any;
  updatedFac: Fac;
  valid: boolean;
  claims: IParsedClaims;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private api: DefaultApi,
    private snackBar: SnackService,
    private claimsSvc: ClaimService
  ) {
    super();
  }

  async ngOnInit() {
    this.claims = this.claimsSvc.get();
    if (this.claims.isSysAdmin) {
      this.orgs = (await this.api.adminOrgControllerIndex()).data;
      const orgId = this.route.snapshot.paramMap.get('id') as string;
      this.data = { ...this.data, orgId: orgId || this.claims.orgId };
    } else {
      this.orgs = [
        (await this.api.adminOrgControllerGet(this.claims.orgId)).data,
      ];
      this.data = { ...this.data, orgId: this.claims.orgId };
    }
  }

  back(orgId?: string) {
    this.router.navigateByUrl(`admin/org/${orgId || this.data.orgId}`);
  }

  async save() {
    try {
      this.busy$$.next(true);
      await this.api.adminFacControllerPost(this.updatedFac);
      this.dirty = false;
      this.snackBar.saved();
      this.back(this.updatedFac.orgId);
      this.busy$$.next(false);
    } catch (error) {
      this.snackBar.oops();
    }
  }

  valueChange(updatedFac: Fac) {
    this.dirty = true;
    this.updatedFac = updatedFac;
  }

  statusChange(valid: boolean) {
    this.valid = valid;
  }
}
