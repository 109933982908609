<h1><mat-icon>account_circle</mat-icon> Your Profile</h1>

<mat-card class="mat-elevation-z8 mw-880 mb-2">
  <evo-busy [busy]="!org || !user">
    <ng-container *ngIf="org && user">
      <h2><mat-icon>assured_workload</mat-icon> {{ org.name }}</h2>
      <ng-container *ngIf="user?.isAdmin">
        <h3 class="my-1">
          <ng-container *ngIf="!user?.isSysAdmin; else admin">
            <mat-icon>security</mat-icon> App Administrator
          </ng-container>
          <ng-template #admin>
            <mat-icon>electric_bolt</mat-icon> System Administrator
          </ng-template>
        </h3>
      </ng-container>
      <evo-user-profile-form
        [data]="user"
        (valueChange)="valueChange($event)"
        (statusChange)="statusChange($event)"
      >
      </evo-user-profile-form>
    </ng-container>
  </evo-busy>

  <evo-busy [busy]="busy$ | async">
    <button
      class="mt-2"
      mat-raised-button
      color="primary"
      [disabled]="!valid || !dirty"
      (click)="save()"
    >
      <mat-icon>save</mat-icon> Save
    </button>
  </evo-busy>
</mat-card>

<evo-fac-list
  *ngIf="user && user?.org?.facilities?.length"
  [facs]="user?.org?.facilities"
  icon="home_work"
  title="Facilities"
></evo-fac-list>
