<evo-page>
  <div class="gray vh-100">
    <evo-app-bar [link]="uiUrlAccount">
      <div class="button-group row flex-end">
        <button color="warn" mat-mini-fab [matMenuTriggerFor]="menu">
          <mat-icon>person</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngIf="claims">
            <div class="mb-1 p-2" mat-list-item>
              <div class="mat-body-2">{{ claims.email }}</div>
              <div>{{ claims.phone }}</div>
            </div>
          </ng-container>
          <evo-router-links [routes]="routes"></evo-router-links>
          <button mat-menu-item (click)="logout()">
            <mat-icon color="accent">logout</mat-icon> Sign out
          </button>
        </mat-menu>
      </div>
    </evo-app-bar>
    <div class="p-1">
      <router-outlet></router-outlet>
    </div>
  </div>
</evo-page>
