import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppEnum, oneAppConfig } from '@evo/iso/common';
import { BaseComponent } from '@evo/ui/common';
import { map, tap } from 'rxjs';

@Component({
  selector: 'evo-app-form',
  templateUrl: './app-form.component.html',
})
export class AppFormComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() apps: AppEnum[] = [];
  @Input() availableApps: AppEnum[];
  @Input() edit = false;
  @Output() valueChange = new EventEmitter();
  form: FormGroup;
  oneAppConfig = oneAppConfig;

  constructor(private fb: FormBuilder) {
    super();
  }

  async ngOnInit() {
    this.form = this.fb.group({});
    this.availableApps.forEach((app) =>
      this.form.addControl(app, new FormControl(this.apps.includes(app)))
    );
    this.subscriptions.push(
      this.form.valueChanges
        .pipe(
          map((v) =>
            Object.entries(v)
              .filter(([k, v]) => !!v)
              .map(([k]) => k)
          ),
          tap((v) => this.valueChange.emit(v))
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
