import { Component, OnInit } from '@angular/core';
import { BaseComponent, SnackService } from '@evo/ui/common';
import { DefaultApi, StripePromo, Org, User } from '@evo/generated/admin';
import { Router } from '@angular/router';

@Component({
  templateUrl: './system-stripe-create-promo-page.component.html',
})
export class SystemStripeCreatePromoPageComponent extends BaseComponent implements OnInit {
  user: User;
  org: Org;
  stripePromos: StripePromo[];
  value: StripePromo;
  invalid = true;

  constructor(
    private api: DefaultApi,
    private snackBar: SnackService,
    private router: Router
  ) {
    super();
  }

  async valueChange ($event: StripePromo) {
    this.value = $event;

  }

  statusChange ($event: boolean) {
    this.invalid = !$event;
  }

  async save ($event: StripePromo) {
    if (this.stripePromos.some(promo => promo.id === $event.id)) {
      this.invalid = true;
      return this.snackBar.open('Promo code must be unique');
    }

    await this.api.systemStripePromoControllerPost($event);

    this.snackBar.saved();
  }

  async ngOnInit() {
    this.user = (await this.api.userControllerGet()).data;
    this.org = this.user.org as Org;
    await this.loadStripePromos();
  }

  async loadStripePromos() {;
    this.stripePromos = (await this.api.systemStripePromoControllerIndex()).data;
  }

  back () {
    this.router.navigate(['system', 'promos']);
  }
}
